<template>
    <div class="users-by-role-container">
        <div class="button-container">
            <button class="btn round primary" v-on:click="showAddUsersModal()">
                <span>{{$t("addUsersButtonText")}}</span>
                </button>
        </div>
        <div class="users-table-header row ml-0 mr-0">
            <label for="roles">{{`${$t("roles")}:`}}</label>
            <select name="roles" v-model="selectedRole">
                <option disabled v-bind:v-model="null">{{$t("defaultSelectOption")}}</option>
                <option v-for="role in getAvailableRoles"
                        :key="role.roleId"
                        :value="role.roleId">{{role.name}}</option>
            </select>
        </div>
        <div class="groups-container">
            <users :collection="currentCollection"/>
        </div>
        <modal v-if="showModal"
            :modalType="modalType"
            :clickOutSideModalHandler="hideModal"
            :showButtons="showModalButtons">
            <add-users v-on:close="hideModal"/></modal>
    </div>
</template>

<script>

import Users from "@/components/BackOffice/ManagementDashboard/Users.vue";
import AddUsers from "@/components/BackOffice/ManagementDashboard/AddUsers.vue";
import Modal from "@/common/Modal.vue";
import { Role } from "@/utils/Roles.js";
import AuthenticationService from "@/services/AuthenticationService.js";

export default {
    name: "UsersByRole",
    components: {
        Users,
        AddUsers,
        Modal
    },
    data: function(){
        return {
            showModal: false,
            modalType: "",
            showModalButtons: false,
            selectedRole: parseInt(AuthenticationService.getProfile().roleId)
        }
    },
    computed: {
        currentCollection(){
            return this.$store.state.usersCollection[this.selectedRole] || { users: [] };
        },
        currentUser(){
            return AuthenticationService.getProfile();
        },
        getAvailableRoles(){
            let _roleName = this.currentUser.roleName.replace(/\s/g, "");
            
            return [Role[_roleName], ...Role[_roleName].toAddAllowedRoles()];
        }
    },
    methods: {
        showAddUsersModal(){
            this.showModal = true;
        },
        hideModal(){
            this.showModal = false;
        }
    },
    created: function(){
        this.$store.dispatch("getUsers")
            .then(null, error => {
                this.$store.commit('addSnackBarNotification', {
                    message: this.$t(error), 
                    dismissable: true
                });
            });

        this.$store.commit("setManagementDashboardNavBarPath", {
            parent: this.$t("management"),
            child: this.$t("users")
        });        
    },
    mounted(){
        this.startTrackingPage({ sectionName: "Users Management Dashboard" });
        this.$store.commit("setAnalyzedManagementSection", "Users Management Dashboard");
    },
    beforeDestroy(){
        this.$store.commit("setAnalyzedManagementSection", "");
        this.stopTrackingPage({ sectionName: "Users Management Dashboard" });
    }
}

</script>

<style lang="less" scoped>

.users-by-role-container{
    width: 968px;
    margin: 10px auto;

    .button-container{
        width: 100%;
        margin-bottom: 5px;

        button{
            height: 40px;
            width: 108px;
            margin-left: 858px;
        }
    }

    .users-table-header{
        height: 56px;
        color: white;
        background-color: #0A1554;
        line-height: 56px;

        label{
            margin-left: auto;
            margin-right: 10px;
        }

        select{
            min-height: 24px;
            max-height: 24px;
            width: 150px;
            margin-right: 10px;
            margin-top: 16px;

            option{
                line-height: 16px;
                font-size: 16px;
            }
        }
    }

    .groups-container{
        height: calc(100vh - 225px);
        overflow-y: auto;

        &::-webkit-scrollbar{
            display: none;
        }
    }
}

</style>

