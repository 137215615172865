var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "add-element-container column" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "row" }, [
        _c("p", [_vm._v(_vm._s(this.$t("addDistrictsText")))]),
        _c(
          "i",
          { staticClass: "circle close-tag", on: { click: _vm.hideModal } },
          [_c("div", { staticClass: "cross" })]
        )
      ])
    ]),
    _c("div", { staticClass: "add-element-fields column" }, [
      _c(
        "label",
        {
          class: [
            {
              visibility: _vm.districtName == "",
              errorMessage: !_vm.isValidDistrictName
            }
          ],
          attrs: { for: "name" }
        },
        [
          _vm._v(
            "\n            " +
              _vm._s(
                "" +
                  (_vm.isValidDistrictName
                    ? _vm.$t("addDistrictNameLabelText")
                    : !_vm.isDistrictNameAvailable
                      ? _vm.$t("invalidName")
                      : _vm.$t("mustFillNameField"))
              )
          )
        ]
      ),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.districtName,
            expression: "districtName"
          }
        ],
        attrs: {
          type: "text",
          name: "name",
          placeholder: _vm.$t("addDistrictNameLabelText")
        },
        domProps: { value: _vm.districtName },
        on: {
          blur: _vm.checkDistrictNameAvailabality,
          focus: _vm.focusEventHandler,
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.districtName = $event.target.value
          }
        }
      }),
      _c("label", { attrs: { for: "states" } }, [
        _vm._v(_vm._s(_vm.withCapitalLetter(_vm.$t("states"))))
      ]),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedState,
              expression: "selectedState"
            }
          ],
          attrs: { name: "states" },
          on: {
            change: function($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function(o) {
                  return o.selected
                })
                .map(function(o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.selectedState = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            }
          }
        },
        [
          _c("option", { attrs: { disabled: "" }, domProps: { value: null } }, [
            _vm._v(_vm._s(_vm.$t("defaultSelectOption")))
          ]),
          _vm._l(_vm.states, function(state, index) {
            return _c("option", { key: index, domProps: { value: state.id } }, [
              _vm._v(
                "\n                " + _vm._s(state.name) + "\n            "
              )
            ])
          })
        ],
        2
      )
    ]),
    _c("div", { staticClass: "add-element-buttons" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "button",
            {
              on: {
                click: function($event) {
                  _vm.cleanFormFields()
                }
              }
            },
            [
              _c("span", { staticClass: "clean-button-text" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("cleanButtonText"))
                )
              ])
            ]
          ),
          _c(
            "button",
            {
              class: [{ disabled: !_vm.validForm }],
              on: {
                click: function($event) {
                  _vm.addDistricts()
                }
              }
            },
            [
              _c("span", { staticClass: "add-element-submit-button-text" }, [
                _vm._v(
                  "\n                        " + _vm._s(_vm.$t("addButtonText"))
                )
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }