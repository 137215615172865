<template>
    <div class="addUsers-container column">
        <div class="header row ml-0 mr-0">
            <span >{{$t('updateUsersHeaderText')}}</span>
            <span class="close-icon" @click="hideModal">&times;</span>
        </div>
        <form action="send" @submit.prevent="editUsers" method="post" :autocomplete="'off'">
            <div class="addUsers-credentials column">
                <label for="firstName" 
                    :class="[{visibility: firstName == '', errorMessage: !isValidFirstName}]">
                    {{`${isValidFirstName ? $t("addUsersFirstNameLabelText") 
                                            : $t("mustFillNameField")}`}}</label>
                <input type="text" name="firstName" v-model="firstName" 
                        :placeholder='$t("addUsersFirstNameLabelText")'/>

                <label for="middleName" :class="[{visibility: middleName == ''}]">
                    {{$t("addUsersMiddleNameLabelText")}}</label>
                <input type="text" name="middleName" v-model="middleName" 
                        :placeholder='$t("addUsersMiddleNameLabelText")'/>

                <label for="lastName" 
                    :class="[{visibility: lastName == '', errorMessage: !isValidLastName}]">
                    {{`${isValidLastName ? $t("addUsersLastNameLabelText") 
                                            : $t("mustFillNameField")}`}}</label>
                <input type="text" name="lastName" v-model="lastName" 
                        :placeholder='$t("addUsersLastNameLabelText")'/>

                <label for="secondLastName" :class="[{visibility: secondLastName == ''}]">
                    {{$t("addUsersSecondLastNameLabelText")}}</label>
                <input type="text" name="secondLastName" v-model="secondLastName" 
                        :placeholder='$t("addUsersSecondLastNameLabelText")'/>

                <label for="emailaddress" 
                    :class="[{visibility: emailAddress == '', errorMessage: !isValidEmailAddress}]">
                    {{`${isValidEmailAddress ? $t("addUsersEmailAddressLabelText") 
                                                : $t("invalidEmailAddress")}`}}</label>
                <input type="text" name="emailaddress" v-model="emailAddress" 
                        :placeholder='$t("addUsersEmailAddressLabelText")'/>

                <label :class="[{errorMessage: !isValidBirthDate}]">
                    {{`${isValidBirthDate ? $t("birthDate") 
                                                : $t("invalidBirthDate")}`}}</label>
                <datepicker v-model="birthDate" 
                            :language="datePickerLanguage" 
                            :format="birthDateFormat"></datepicker>      

                <template v-if="showCampusField">
                    <label for="campus">{{$t("addUsersCampusLabelText")}}</label>
                    <select name="campus" v-model="selectedCampus">
                        <option disabled value="-1">{{$t("defaultSelectOption")}}</option>
                        <option v-for="(campus, index) in toAddAllowedCampus" 
                            v-bind:key="index" v-bind:value="campus.schoolId">
                            {{campus.name}}
                        </option>
                    </select>
                </template>
            </div>
            <div class="buttons row">
                <button class="btn secondary" @click="cleanFormFields">{{$t('addUsersCleanButtonText')}}</button>
                <input type="submit" class="btn primary" :class="[{'disabled': !validForm}]" :value="$t('updateUsersButtonText')"/>
            </div>
        </form>
    </div>
</template>

<script>

import { Role } from "@/utils/Roles.js";
import Datepicker from 'vuejs-datepicker';
import {en, es} from "vuejs-datepicker/dist/locale";
import moment from "moment";
import ValidationHelper from "@/utils/ValidationHelper.js";

export default{
    name: "EditUsers",
    components: {
        Datepicker,
        ValidationHelper
    },
    props:{
        user: Object
    },
    data: function(){
        return {
            firstName: this.user.firstName,
            middleName: this.user.middleName || "",
            lastName: this.user.lastName, 
            secondLastName: this.user.secondLastName || "",
            emailAddress: this.user.email,
            selectedCampus: this.user.schoolId,
            birthDate: this.user.birthDate,

            showCampusField: this.user.roleId === Role.CampusManager.roleId
        };
    },
    computed: {
        validForm(){
            return  this.isValidFirstName 
                    && this.isValidLastName
                    && this.isValidEmailAddress
                    && this.isValidBirthDate
                    && this.isValidSelectedCampus;
        },
        toAddAllowedCampus(){
            return this.$store.state.schools;
        },
        datePickerLanguage(){
            return this.$i18n.locale === "en" ? en : es;
        },
        isValidFirstName(){
            return this.firstName.trim() != "";
        },
        isValidLastName(){
            return this.lastName.trim() != "";
        },
        isValidEmailAddress(){
            return this.emailAddress != "" && ValidationHelper.validateEmail(this.emailAddress);
        },
        isValidBirthDate(){
            let _date = moment(this.birthDate);
            return _date.isValid() && _date.isAfter(moment().subtract(100, "y"));
        },
        isValidSelectedCampus(){
            return this.showCampusField ? this.selectedCampus !== "" : true;
        }
    },
    methods: {
        editUsers(){
            if(!this.validForm){
                this.$store.commit('addSnackBarNotification', {
                        message: this.$t("mustFillFields"), 
                        dismissable: false
                    });

                return;
            }

            this.$store.dispatch("editUser", { 
                ...this.user,
                firstName: this.firstName,
                middleName: this.middleName,
                lastName: this.lastName,
                secondLastName: this.secondLastName,
                email: this.emailAddress,
                birthDate: moment(this.birthDate).format(),
                schoolId: this.selectedCampus,
            }).then(() => {
                this.hideModal();
                this.$store.commit('addSnackBarNotification', {
                        message: this.$t("successfulUserUpdatingMessage"), 
                        dismissable: false
                    });
            }, error => {
                this.$store.commit('addSnackBarNotification', {
                        message: this.$t(error), 
                        dismissable: false
                    });
            });
        },
        birthDateFormat(date){
            if(!date || date === "")
                return date;

            const enFormat = "MM/DD/YYYY";
            const esFormat = "DD/MM/YYYY";

            let format = this.$i18n.locale === "en" ? enFormat : esFormat;
            return moment(date).format(format);
        },
        cleanFormFields(e){
            e.preventDefault();
            this.firstName = this.user.firstName;
            this.middleName = this.user.middleName;
            this.lastName = this.user.lastName;
            this.secondLastName = this.user.secondLastName;
            this.emailAddress = this.user.email;
            this.selectedCampus = this.user.schoolId;
            this.birthDate = this.user.birthDate;
        },
        hideModal(){
            this.$emit("close");
        }
    }
}
</script>
<style lang="less" scoped>
.addUsers-container{
    margin: 0px auto;
    width: 329px;
    padding: 20px;

    .header{
        width: 100%;
        background-color: #EAEBFF;
        min-height: 24px;
        span{
            align-self: center;
            margin-left: 14px;
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 16px;
            white-space: normal;
            text-transform: uppercase;
        }
        .close-icon{
            margin-right: 16px;
            margin-left: auto;
            justify-self: flex-end;
            align-self: center;
            font-size: 2em;
            font-weight: 400;
            color: rgba(10, 21, 84, 0.6);
            &:hover{
                cursor: pointer;
            }
        }
        
    }
    .addUsers-credentials{
        width: 100%;
        margin-top: 30px;
        margin-bottom: 26px;

        label{
            height: 24px;
            color: rgba(0,0,0,0.7);
            font-family: Roboto;
            font-size: 12px;
            line-height: 12px;

            margin-bottom: 6px;
        }

        .errorMessage{
            color: red;
        }

        label.visibility{
            // visibility: hidden;
        }

        input, select{
            width: 100%;
            min-height: 24px;
            max-height: 24px;

            &:focus{
                outline: none;
            }
        }

        input{
            border: none;
            border-bottom: 1px solid #9E9E9E;
            padding-bottom: 3px;

            &:hover{
                cursor: auto;
            }
        }

        & /deep/ .vdp-datepicker input, 
        input, select{
            width: 100%;
            min-height: 24px;
            max-height: 24px;
            margin-bottom: 10px;

            color: rgba(0,0,0,0.54);
            font-family: Roboto;
            font-size: 16px;
            line-height: 24px;

            &:focus{
                outline: none;
            }
        }

        & /deep/ .vdp-datepicker > div{
            width: 100%;
            bottom: 29px;
        }
    }

    .buttons{
        width: 100%;
        margin-bottom: 0;
        .btn{
            &.secondary{
                margin-left: auto;
                margin-right: 8px;
            }
            width: 90px;
            min-height: 32px;
            max-height: 32px;
        }
        .btn.primary {
            color: #fff !important;
        }
    }

}
</style>
