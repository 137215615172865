<template>
    <div class="management-dashboard">
        <component :is="currentSection"></component>
    </div>
</template>

<script>

import UsersByRole from "@/components/BackOffice/ManagementDashboard/UsersByRole.vue";
import Districts from "@/components/BackOffice/ManagementDashboard/Districts/Districts.vue";
import States from "@/components/BackOffice/ManagementDashboard/States/States.vue";
import Schools from "@/components/BackOffice/ManagementDashboard/Schools/Schools.vue";
import TopVisitedSectionsPerRole from "@/components/BackOffice/ManagementDashboard/Analytics/TopVisitedSectionsPerRole.vue";
import ClassRoomManagement from "@/views/ClassRoomManagement.vue";

export default {
    name: "ManagementDashboard",
    components: {
        "users": UsersByRole,
        "classRooms": ClassRoomManagement,
        "districts": Districts,
        "states": States,
        "schools": Schools,
        "topVisitedPagesPerRole": TopVisitedSectionsPerRole
    },
    computed:{
        currentSection(){
            return this.$store.state.currentManagementSection;
        }
    },
    created: function(){
        this.$store.commit("setManagmentCurrentSection", "users");
        this.$store.commit("updateShowPreviewModeOptions", false);
    },
    beforeDestroy: function(){
        this.$store.commit("updateShowPreviewModeOptions", true);
    }
}
</script>

<style lang="less" scoped>
.management-dashboard{
    position: absolute;
    top: 104px;
    width: 100%;

    & /deep/ .class-management{
        margin: 0px auto;
    }
}
</style>
