var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "management-dashboard-section-container" },
    [
      _c("div", { staticClass: "button-container" }, [
        _c(
          "button",
          {
            staticClass: "btn round primary",
            on: {
              click: function($event) {
                _vm.showAddStateModal()
              }
            }
          },
          [_c("span", [_vm._v(_vm._s(_vm.$t("addStateText").toUpperCase()))])]
        )
      ]),
      _vm._m(0),
      _c(
        "div",
        { staticClass: "section-body" },
        [
          _c("table", [
            _c("thead", [
              _c("tr", [
                _c("th", [
                  _vm._v(_vm._s(_vm.withCapitalLetter(_vm.$t("name"))))
                ]),
                _c("th")
              ])
            ]),
            _c(
              "tbody",
              [
                _vm._l(_vm.partialCollection, function(state, index) {
                  return _c("tr", { key: index }, [
                    _c("td", [_vm._v(_vm._s(state.name))]),
                    _c("td", [
                      _c("img", {
                        attrs: { src: _vm.getEditIcon },
                        on: {
                          click: function($event) {
                            _vm.showUpdateStateModal(state)
                          }
                        }
                      }),
                      _c("img", {
                        attrs: { src: _vm.getDeleteIcon },
                        on: {
                          click: function($event) {
                            _vm.showDeleteConfirmation(state)
                          }
                        }
                      })
                    ])
                  ])
                }),
                _vm.partialCollection.length === 0
                  ? _c("tr", [
                      _c(
                        "td",
                        { staticClass: "no-content", attrs: { colspan: "2" } },
                        [_vm._v(_vm._s(_vm.$t("noContent")))]
                      )
                    ])
                  : _vm._e()
              ],
              2
            )
          ]),
          _c("paginator", {
            attrs: { collection: _vm.collection },
            on: { onPartialCollectionUpdate: _vm.eventHandler }
          })
        ],
        1
      ),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                modalType: _vm.modalType,
                clickOutSideModalHandler: _vm.hideModal,
                showButtons: _vm.showModalButtons
              }
            },
            [
              _c(_vm.currentModal, {
                tag: "component",
                attrs: { state: _vm.selectedState },
                on: { close: _vm.hideModal }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showDeleteModal
        ? _c(
            "modal",
            {
              attrs: {
                "click-handler": _vm.deleteState,
                clickOutSideModalHandler: _vm.hideModal
              },
              on: { close: _vm.hideModal }
            },
            [
              _c("div", { staticClass: "removeQuestion" }, [
                _c("span", { staticClass: "removeQuestion" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t("wantToDeleteStateQuestion") +
                          ": " +
                          _vm.selectedState.name +
                          "?"
                      )
                  )
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "section-header" }, [
      _c("div", { staticClass: "col-12" }, [_c("div", { staticClass: "row" })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }