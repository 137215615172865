<template>
    <div class="addUsers-container column">
        <div class="header row ml-0 mr-0">
            <span >{{$t('addUsersHeaderText')}}</span>
            <span class="close-icon" @click="hideModal">&times;</span>
        </div>
        <form @submit.prevent="addUsers" method="post" :autocomplete="'off'">
        <div class="addUsers-credentials column">
            <label for="firstName" 
                :class="[{visibility: firstName == '', errorMessage: !isValidFirstName}]">
                {{`${isValidFirstName ? $t("addUsersFirstNameLabelText") 
                                        : $t("mustFillNameField")}`}}</label>
            <input type="text" name="firstName" v-model="firstName" 
                    :placeholder='$t("addUsersFirstNameLabelText")'/>

            <label for="middleName" :class="[{visibility: middleName == ''}]">
                {{$t("addUsersMiddleNameLabelText")}}</label>
            <input type="text" name="middleName" v-model="middleName" 
                    :placeholder='$t("addUsersMiddleNameLabelText")'/>

            <label for="lastName" 
                :class="[{visibility: lastName == '', errorMessage: !isValidLastName}]">
                {{`${isValidLastName ? $t("addUsersLastNameLabelText") 
                                        : $t("mustFillNameField")}`}}</label>
            <input type="text" name="lastName" v-model="lastName" 
                    :placeholder='$t("addUsersLastNameLabelText")'/>

            <label for="secondLastName" :class="[{visibility: secondLastName == ''}]">
                {{$t("addUsersSecondLastNameLabelText")}}</label>
            <input type="text" name="secondLastName" v-model="secondLastName" 
                    :placeholder='$t("addUsersSecondLastNameLabelText")'/>

            <label for="emailaddress" 
                :class="[{visibility: emailAddress == '', errorMessage: !isValidEmailAddress}]">
                {{`${isValidEmailAddress ? $t("addUsersEmailAddressLabelText") 
                                            : $t("invalidEmailAddress")}`}}</label>
            <input type="text" name="emailaddress" v-model="emailAddress" 
                    :placeholder='$t("addUsersEmailAddressLabelText")'/>
            
            <label for="username" 
                :class="[{visibility: username == '', errorMessage: !isValidUsername}]">
                {{`${isValidUsername ? $t("username") 
                                            : isUserNameAvailable ? 
                                                $t("invalidUsername")
                                                    : $t("usernameInUse")}`}}</label>
            <input type="text" name="username" 
                    v-on:blur="checkUsernameAvailabality" 
                    v-on:focus="focusEventHandler"
                    v-model="username" 
                    :placeholder='$t("username")'/>

            <label :class="[{errorMessage: !isValidBirthDate}]">
                {{`${isValidBirthDate ? $t("birthDate") 
                                            : $t("invalidBirthDate")}`}}</label>
            <datepicker v-model="birthDate" 
                        :language="datePickerLanguage" 
                        :format="birthDateFormat"></datepicker>
            
            <label for="userRole">{{$t("addUsersRoleLabelText")}}</label>
            <select name="userRole" v-model="selectedRole" v-on:change="selectOnChangeEvent">
                <option disabled value="-1">{{$t("defaultSelectOption")}}</option>
                <option v-for="(role, index) in toAddAllowedRoles" 
                        v-bind:key="index" v-bind:value="role.roleId">
                    {{role.name}}
                </option>
            </select>
            <template v-if="showDistrictField">
                <label for="district">{{$t("addUsersDistrictLabelText")}}</label>
                <select name="district" v-model="selectedDistrict">
                    <option disabled value="-1">{{$t("defaultSelectOption")}}</option>
                    <option v-for="(district, index) in toAddAllowedDistrict" 
                        v-bind:key="index" v-bind:value="district.id">
                        {{district.name}}
                    </option>
                </select>
            </template>
            <template v-if="showCampusField">
                <label for="campus">{{$t("addUsersCampusLabelText")}}</label>
                <select name="campus" v-model="selectedCampus">
                    <option disabled value="-1">{{$t("defaultSelectOption")}}</option>
                    <option v-for="(campus, index) in toAddAllowedCampus" 
                        v-bind:key="index" v-bind:value="campus.id">
                        {{campus.name}}
                    </option>
                </select>
            </template>
        </div>
        <div class="buttons row">
                <button @click="cleanFormFields" class="btn secondary">{{$t('cleanButtonText')}}</button>
                <input type="submit" class="btn primary" :class="[{'disabled': !validForm}]"
                :value="$t('addUsersButtonText')"/>
        </div>
        </form>  
    </div>
</template>

<script>

import AuthenticationService from "@/services/AuthenticationService.js";
import { Role } from "@/utils/Roles.js";
import Datepicker from 'vuejs-datepicker';
import {en, es} from "vuejs-datepicker/dist/locale";
import moment from "moment";
import ValidationHelper from "@/utils/ValidationHelper.js";

export default{
    name: "AddUsers",
    components: {
        Datepicker,
        ValidationHelper
    },
    data: function(){
        return {
            firstName: "",
            middleName: "",
            lastName: "",
            secondLastName: "",
            emailAddress: "",
            username: "",
            selectedRole: -1,
            selectedCampus: -1,
            selectedDistrict: -1,
            birthDate: Date.now(),

            showCampusField: false,
            showDistrictField: false,
            isUserNameAvailable: true,
        };
    },
    computed: {
        validForm(){
            return  this.isValidFirstName 
                    && this.isValidLastName
                    && this.isValidEmailAddress
                    && this.isValidUsername
                    && this.isValidBirthDate
                    && this.isValidSelectedRole 
                    && this.isValidSelectedCampus
                    && this.isValidSelectedDistrict;
        },
        toAddAllowedRoles(){
            let roleName = this.loggedUser.roleName.replace(/\s/g, "");
            let roleObj = null;

            return (roleObj = Role[roleName]) && roleObj.toAddAllowedRoles ? 
                        roleObj.toAddAllowedRoles() : [];
        },
        toAddAllowedCampus(){
            return this.$store.state.schools;
        },
        toAddAllowedDistrict(){
            return this.$store.state.managementObj.districts;
        },
        datePickerLanguage(){
            return this.$i18n.locale === "en" ? en : es;
        },
        loggedUser(){
            return AuthenticationService.getProfile();
        },
        isValidFirstName(){
            return this.firstName.trim() != "";
        },
        isValidLastName(){
            return this.lastName.trim() != "";
        },
        isValidEmailAddress(){
            return this.emailAddress != "" && ValidationHelper.validateEmail(this.emailAddress);
        },
        isValidUsername(){
            return this.username != "" && ValidationHelper.validUsername(this.username)
                    && this.isUserNameAvailable;
        },
        isValidBirthDate(){
            let _date = moment(this.birthDate);
            return _date.isValid() && _date.isAfter(moment().subtract(100, "y"));
        },
        isValidSelectedRole(){
            return this.selectedRole >= 0;
        },
        isValidSelectedCampus(){
            return this.showCampusField ? this.selectedCampus !== -1 : true;
        },
        isValidSelectedDistrict(){
            return this.showDistrictField ? this.selectedDistrict !== -1 : true;
        }
    },
    methods: {
        addUsers(){
            if(!this.validForm){
                this.$store.commit('addSnackBarNotification', {
                        message: this.$t("mustFillFields"), 
                        dismissable: false
                    });

                return;
            }

            this.$store.state.dealerPromise.promise
                .then(() => {
                    this.$store.dispatch("addNewUser", { 
                        firstName: this.firstName,
                        middleName: this.middleName,
                        lastName: this.lastName,
                        secondLastName: this.secondLastName,
                        email: this.emailAddress,
                        username: this.username,
                        birthDate: moment(this.birthDate).format(),
                        schoolId: this.selectedCampus !== -1 ? this.selectedCampus : null,
                        districtId: this.selectedDistrict !== -1 ? this.selectedDistrict : null,
                        roleId: this.selectedRole
                    }).then(() => {
                        this.hideModal();
                        this.$store.commit('addSnackBarNotification', {
                                message: this.$t("successfulAddUsersMessage"), 
                                dismissable: false
                            });
                    }, error => {
                        this.$store.commit('addSnackBarNotification', {
                                message: this.$t(error), 
                                dismissable: false
                            });
                    });
                }, () => {
                    this.$store.commit('addSnackBarNotification', {
							message: this.$t("usernameInUse"), 
							dismissable: true
						});
                });
        },
        birthDateFormat(date){
            if(!date || date === "")
                return date;

            const enFormat = "MM/DD/YYYY";
            const esFormat = "DD/MM/YYYY";

            let format = this.$i18n.locale === "en" ? enFormat : esFormat;
            return moment(date).format(format);
        },
        cleanFormFields(e){
            e.preventDefault();
            this.firstName = "";
            this.middleName = "";
            this.lastName = "";
            this.secondLastName = "";
            this.emailAddress = "";
            this.username = "";
            this.selectedRole = -1;
            this.selectedCampus = -1;
            this.selectedDistrict = -1;
            this.birthDate = Date.now();
        },
        selectOnChangeEvent(event){
            this.showCampusField = false;
            this.showDistrictField = false;

            switch(event.target.value){
                case Role.ContentCreator.roleId.toString():
                case Role.DistrictTeacher.roleId.toString():
                case Role.CampusManager.roleId.toString(): {
                    if(this.loggedUser.roleName == Role.CampusManager.name)
                        this.selectedCampus = this.loggedUser.schoolId;
                    else
                        this.$store.dispatch("getSchools")
                            .then(() => {
                                this.showCampusField = true;
                            }, error => {
                                this.$store.commit('addSnackBarNotification', {
                                    message: this.$t(error), 
                                    dismissable: false
                                });
                            });

                    break;
                }

                case Role.DistrictManager.roleId.toString(): {
                    this.$store.dispatch("getDistricts")
                    .then(() => {
                        this.showDistrictField = true;
                    }, error => {
                        this.$store.commit('addSnackBarNotification', {
                            message: this.$t(error), 
                            dismissable: false
                        });
                    });

                    break;
                }

                default:
                    break;
            }
        },
        checkUsernameAvailabality(){
            if(!this.username)
                return;

            this.$store.dispatch("checkUsernameAvailabality", this.username)
                        .then(() => { this.isUserNameAvailable = false; })
                        .catch(() => { this.isUserNameAvailable = true; });
        },
        focusEventHandler(){
            this.$store.dispatch("setDealerPromise");
            this.isUserNameAvailable = true;
        },
        hideModal(){
            this.$emit("close");
        }
    },
    beforeDestroy(){
        this.$store.commit("setDealerPromise", null);
    }
}
</script>
<style lang="less" scoped>
.addUsers-container{
    margin: 0px auto;
    width: 330px;
    padding-bottom: 0px !important;
    padding: 24px;

    .header{
        width: 100%;
        background-color: #EAEBFF;
        min-height: 24px;
        span{
            align-self: center;
            margin-left: 14px;
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 16px;
            white-space: normal;
            text-transform: uppercase;
        }
        .close-icon{
            margin-right: 16px;
            margin-left: auto;
            justify-self: flex-end;
            align-self: center;
            font-size: 2em;
            font-weight: 400;
            color: rgba(10, 21, 84, 0.6);
            &:hover{
                cursor: pointer;
            }
        }
        
    }

    .addUsers-credentials{
        width: 100%;
        margin-bottom: 26px;
        label{
            height: 24px;
            color: rgba(0,0,0,0.7);
            font-family: Roboto;
            font-size: 12px;
            line-height: 12px;
            padding-top: 8px;

            // margin-bottom: 6px;
        }

        .errorMessage{
            color: red;
        }

        label, div{
            &.visibility{
                visibility: hidden;
            }
        }

        input, select{
            width: 100%;
            min-height: 24px;
            max-height: 24px;

            &:focus{
                outline: none;
            }
        }

        input{
            border: none;
            border-bottom: 1px solid #9E9E9E;
            padding-bottom: 3px;
            
            &:hover{
                cursor: auto;
            }
        }

        & /deep/ .vdp-datepicker input, 
        input, select{
            width: 100%;
            min-height: 24px;
            max-height: 24px;
            margin-bottom: 4px;

            color: rgba(0,0,0,0.54);
            font-family: Roboto;
            font-size: 16px;
            line-height: 24px;

            &:focus{
                outline: none;
            }
        }

        select:last-child{
            margin-bottom: 0px;
        }

        & /deep/ .vdp-datepicker > div{
            width: 100%;
            bottom: 29px;
        }
    }

    .buttons{
        width: 100%;
        margin-bottom: 0;
        .btn{
            &.secondary{
                margin-left: auto;
                margin-right: 8px;
            }
            width: 90px;
        }
        .btn.primary {
            color: #fff !important;
        }
    }
}
</style>
