<template>
    <div class="users-container">
        <table>
            <thead>
                <tr>
                    <th>{{$t("firstName")}}</th>
                    <th>{{$t("addUsersEmailAddressLabelText")}}</th>
                    <th>{{$t("username")}}</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(user, index) in partialCollection" :key="index">
                    <td>{{user.firstName}}</td>
                    <td>{{user.email}}</td>
                    <td>{{user.userName}}</td>
                    <td>
                        <img :src="getEditIcon" 
                            v-on:click="showEditUserModal(user)" />
                        <img :src="getDeleteIcon" 
                            v-on:click="showDeleteConfirmation(user)" />
                    </td>
                </tr>
                <tr v-if="partialCollection.length === 0">
                    <td class="no-content" colspan="4">{{$t("noContent")}}</td>
                </tr>
            </tbody>
        </table>
        
        <paginator  :collection="collection.users" 
                    v-on:onPartialCollectionUpdate="eventHandler"/>

        <modal v-if="showModal"
            :modalType="modalType"
            :clickOutSideModalHandler="hideModal"
            :showButtons="showModalButtons">
            <edit-users :user="selectedUser"
                        v-on:close="hideModal"/></modal>

        <modal v-if="showDeleteModal"
            :click-handler="deleteUser"
            :clickOutSideModalHandler="hideModal"
            v-on:close="hideModal">
            <div class="removeQuestion">  
                <span class="removeQuestion">
                    {{`${$t("wantToDeleteUserQuestion")}: ${selectedUser.firstName}?`}}</span>
            </div>
        </modal>
    </div>
</template>

<script>

import EditUsers from "@/components/BackOffice/ManagementDashboard/EditUsers.vue";
import Modal from "@/common/Modal.vue";
import Paginator from "@/common/Paginator.vue";

export default {
    name: "Users",
    components:{
        EditUsers,
        Modal,
        Paginator
    },
    props:{
        collection: Object
    },
    data: function(){
        return {
            partialCollection: [],
            selectedUser: null,
            showModal: false,
            showDeleteModal: false,
            modalType: "",
            showModalButtons: false,
        }
    },
    computed:{
        getDeleteIcon(){
            return require("@/assets/icons/ic_delete.svg");
        },
        getEditIcon(){
            return require("@/assets/icons/ic_edit.svg");
        }
    },
    methods:{
        hideModal(){
            this.showModal = false;
            this.showDeleteModal = false;
        },
        showEditUserModal(user){
            this.selectedUser = user;
            this.showModal = true;
        },
        showDeleteConfirmation(user){
            this.selectedUser = user;
            this.showDeleteModal = true;
        },
        deleteUser(){
            this.showDeleteModal = false;
            this.$store.dispatch("deleteUser", this.selectedUser)
                    .then(() => {
                        this.$store.commit('addSnackBarNotification', {
                            message: this.$t("successfulUserDeletingMessage"), 
                            dismissable: false
                        });

                        this.selectedUser = null;
                    }, error => {
                        this.$store.commit('addSnackBarNotification', {
                                message: this.$t(error), 
                                dismissable: false
                            });
                    });
        },
        eventHandler(event){
            this.partialCollection = event;
        }
    }
}
</script>

<style lang="less" scoped>
.users-container{
    max-width: 968px;
    
    table{
        width: 100%;
        border-collapse: collapse;

        tr{
            height: 56px;
        }

        thead{
            th{
                text-align: left;
            }

            tr:last-child{
                th{
                    width: 30%;
                }

                th:last-child{
                    width: 10%;
                }
            }
        }

        tbody{
            border-top: 1px solid grey;
            border-bottom: 1px solid grey;

            img{
                margin-left: 20px;

                &:hover{
                    cursor: pointer;
                }
            }

            .no-content{
                text-align: center;
            }
        }
    }

    .removeQuestion{
        padding: 20px 0px;
    }

    & /deep/ .paginator-container{
        margin-top: 5px;
    }
}
</style>

