<template>
    <div class="management-dashboard-section-container">
        <div class="button-container">
            <button class="btn round primary" v-on:click="showAddSchoolModal()">
                <span>{{$t("addSchoolText").toUpperCase()}}</span></button>
        </div>
        <div class="section-header">
            <div class="col-12">
                <div class="row">
                </div>
            </div>
        </div>
        <div class="section-body">
            <table>
                <thead>
                    <tr>
                        <th>{{withCapitalLetter($t("name"))}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(school, index) in partialCollection" :key="index">
                        <td>{{school.name}}</td>
                        <td>
                            <img :src="getEditIcon" 
                                v-on:click="showUpdateSchoolModal(school)" />
                            <img :src="getDeleteIcon" 
                                v-on:click="showDeleteConfirmation(school)" />
                        </td>
                    </tr>
                    <tr v-if="partialCollection.length === 0">
                        <td class="no-content" colspan="2">{{$t("noContent")}}</td>
                    </tr>
                </tbody>
            </table>
            
            <paginator  :collection="collection" 
                        v-on:onPartialCollectionUpdate="eventHandler"/>
        </div>
        
        <modal v-if="showModal"
            :modalType="modalType"
            :clickOutSideModalHandler="hideModal"
            :showButtons="showModalButtons">
            <component :is="currentModal"
                        :school="selectedSchool"
                        v-on:close="hideModal"/></modal>

        <modal v-if="showDeleteModal"
            :click-handler="deleteSchool"
            :clickOutSideModalHandler="hideModal"
            v-on:close="hideModal">
            <div class="removeQuestion">  
                <span class="removeQuestion">
                    {{`${$t("wantToDeleteSchoolQuestion")}: ${selectedSchool.name}?`}}</span>
            </div>
        </modal>
    </div>
</template>

<script>

import AddSchools from "@/components/BackOffice/ManagementDashboard/Schools/AddSchools.vue";
import UpdateSchools from "@/components/BackOffice/ManagementDashboard/Schools/UpdateSchools.vue";
import AuthenticationService from "@/services/AuthenticationService.js";
import Modal from "@/common/Modal.vue";
import Paginator from "@/common/Paginator.vue";
import StringUtils from "@/utils/StringUtils.js";

export default {
    name: "Schools",
    components: {
        "add-schools": AddSchools,
        "update-schools": UpdateSchools,
        Modal,
        Paginator,
        StringUtils
    },
    data: function(){
        return {
            currentModal: "",
            showModal: false,
            showDeleteModal: false,
            showModalButtons: false,
            modalType: "vocabularyModal",
            selectedSchool: null,
            partialCollection: []
        }
    },
    computed: {
        getDeleteIcon(){
            return require("@/assets/icons/ic_delete.svg");
        },
        getEditIcon(){
            return require("@/assets/icons/ic_edit.svg");
        },
        collection(){
            return this.$store.state.schools;
        },
        currentUser(){
            return AuthenticationService.getProfile();
        }
    },
    methods: {
        hideModal(){
            this.showModal = false;
            this.showDeleteModal = false;
        },
        showAddSchoolModal(){
            this.currentModal = "add-schools";
            this.showModal = true;
        },
        showUpdateSchoolModal(school){
            this.currentModal = "update-schools";
            this.selectedSchool = school;
            this.showModal = true;
        },
        showDeleteConfirmation(school){
            this.selectedSchool = school;
            this.showDeleteModal = true;
        },
        deleteSchool(){
            this.showDeleteModal = false;
            this.$store.dispatch("deleteSchool", this.selectedSchool)
                    .then(() => {
                        this.$store.commit('addSnackBarNotification', {
                            message: this.$t("successfulSchoolDeletingMessage"), 
                            dismissable: false
                        });

                        this.selectedSchool = null;
                    }, error => {
                        this.$store.commit('addSnackBarNotification', {
                                message: this.$t(error), 
                                dismissable: false
                            });
                    });
        },
        eventHandler(event){
            this.partialCollection = event;
        },
        withCapitalLetter(word){
            return StringUtils.withCapitalLetter(word);
        }
    },
    created: function(){
        this.$store.dispatch("getSchoolsByDistrict", this.currentUser.districtId)
            .then(null, error => {
                this.$store.commit('addSnackBarNotification', {
                    message: this.$t(error), 
                    dismissable: true
                });
            });

        this.$store.commit("setManagementDashboardNavBarPath", {
            parent: this.$t("management"),
            child: this.$t("schools")
        });
    },
    mounted(){
        this.startTrackingPage({ sectionName: "Schools Management Dashboard" });
        this.$store.commit("setAnalyzedManagementSection", "Schools Management Dashboard");
    },
    beforeDestroy(){
        this.$store.commit("setAnalyzedManagementSection", "");
        this.stopTrackingPage({ sectionName: "Schools Management Dashboard" });
    }
}

</script>

<style lang="less" scoped>

.management-dashboard-section-container{
    width: 968px;
    margin: 10px auto;

    .button-container{
        width: 100%;
        margin-bottom: 5px;

        button{
            height: 40px;
            width: 168px;
            margin-left: 798px;
        }
    }

    .section-header{
        height: 56px;
        color: white;
        background-color: #0A1554;
    }

    .section-body{
        max-width: 968px;
        height: calc(100vh - 225px);
        overflow-y: auto;

        &::-webkit-scrollbar{
            display: none;
        }

        table{
            width: 100%;
            border-collapse: collapse;

            tr{
                height: 56px;
            }

            thead{
                th{
                    text-align: left;
                    width: 90%;

                    :last-child{
                        width: 10%;
                    }
                }
            }

            tbody{
                border-top: 1px solid grey;
                border-bottom: 1px solid grey;

                img{
                    margin-left: 20px;

                    &:hover{
                        cursor: pointer;
                    }
                }

                .no-content{
                    text-align: center;
                }
            }
        }

        .removeQuestion{
            padding: 20px 0px;
        }

        & /deep/ .paginator-container{
            margin-top: 5px;
        }
    }
}

</style>

