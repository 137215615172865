var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "update-element-container column" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("div", { staticClass: "row" }, [
        _c("p", [_vm._v(_vm._s(this.$t("updateStatesText")))]),
        _c(
          "i",
          { staticClass: "circle close-tag", on: { click: _vm.hideModal } },
          [_c("div", { staticClass: "cross" })]
        )
      ])
    ]),
    _c("div", { staticClass: "update-element-fields column" }, [
      _c(
        "label",
        {
          class: [
            {
              visibility: _vm.stateName == "",
              errorMessage: !_vm.isValidStateName
            }
          ],
          attrs: { for: "name" }
        },
        [
          _vm._v(
            "\n            " +
              _vm._s(
                "" +
                  (_vm.isValidStateName
                    ? _vm.$t("addStateNameLabelText")
                    : !_vm.isStateNameAvailable
                      ? _vm.$t("invalidName")
                      : _vm.$t("mustFillNameField"))
              )
          )
        ]
      ),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.stateName,
            expression: "stateName"
          }
        ],
        attrs: {
          type: "text",
          name: "name",
          placeholder: _vm.$t("addStateNameLabelText")
        },
        domProps: { value: _vm.stateName },
        on: {
          blur: _vm.checkStateNameAvailabality,
          focus: _vm.focusEventHandler,
          input: function($event) {
            if ($event.target.composing) {
              return
            }
            _vm.stateName = $event.target.value
          }
        }
      })
    ]),
    _c("div", { staticClass: "update-element-buttons" }, [
      _c("div", { staticClass: "col-12" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "button",
            {
              on: {
                click: function($event) {
                  _vm.cleanFormFields()
                }
              }
            },
            [
              _c("span", { staticClass: "reset-button-text" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("resetButtonText"))
                )
              ])
            ]
          ),
          _c(
            "button",
            {
              class: [{ disabled: !_vm.validForm }],
              on: {
                click: function($event) {
                  _vm.updateStates()
                }
              }
            },
            [
              _c("span", { staticClass: "update-element-submit-button-text" }, [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.$t("updateButtonText"))
                )
              ])
            ]
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }