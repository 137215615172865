var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "users-container" },
    [
      _c("table", [
        _c("thead", [
          _c("tr", [
            _c("th", [_vm._v(_vm._s(_vm.$t("firstName")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("addUsersEmailAddressLabelText")))]),
            _c("th", [_vm._v(_vm._s(_vm.$t("username")))]),
            _c("th")
          ])
        ]),
        _c(
          "tbody",
          [
            _vm._l(_vm.partialCollection, function(user, index) {
              return _c("tr", { key: index }, [
                _c("td", [_vm._v(_vm._s(user.firstName))]),
                _c("td", [_vm._v(_vm._s(user.email))]),
                _c("td", [_vm._v(_vm._s(user.userName))]),
                _c("td", [
                  _c("img", {
                    attrs: { src: _vm.getEditIcon },
                    on: {
                      click: function($event) {
                        _vm.showEditUserModal(user)
                      }
                    }
                  }),
                  _c("img", {
                    attrs: { src: _vm.getDeleteIcon },
                    on: {
                      click: function($event) {
                        _vm.showDeleteConfirmation(user)
                      }
                    }
                  })
                ])
              ])
            }),
            _vm.partialCollection.length === 0
              ? _c("tr", [
                  _c(
                    "td",
                    { staticClass: "no-content", attrs: { colspan: "4" } },
                    [_vm._v(_vm._s(_vm.$t("noContent")))]
                  )
                ])
              : _vm._e()
          ],
          2
        )
      ]),
      _c("paginator", {
        attrs: { collection: _vm.collection.users },
        on: { onPartialCollectionUpdate: _vm.eventHandler }
      }),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                modalType: _vm.modalType,
                clickOutSideModalHandler: _vm.hideModal,
                showButtons: _vm.showModalButtons
              }
            },
            [
              _c("edit-users", {
                attrs: { user: _vm.selectedUser },
                on: { close: _vm.hideModal }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.showDeleteModal
        ? _c(
            "modal",
            {
              attrs: {
                "click-handler": _vm.deleteUser,
                clickOutSideModalHandler: _vm.hideModal
              },
              on: { close: _vm.hideModal }
            },
            [
              _c("div", { staticClass: "removeQuestion" }, [
                _c("span", { staticClass: "removeQuestion" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.$t("wantToDeleteUserQuestion") +
                          ": " +
                          _vm.selectedUser.firstName +
                          "?"
                      )
                  )
                ])
              ])
            ]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }