var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "users-by-role-container" },
    [
      _c("div", { staticClass: "button-container" }, [
        _c(
          "button",
          {
            staticClass: "btn round primary",
            on: {
              click: function($event) {
                _vm.showAddUsersModal()
              }
            }
          },
          [_c("span", [_vm._v(_vm._s(_vm.$t("addUsersButtonText")))])]
        )
      ]),
      _c("div", { staticClass: "users-table-header row ml-0 mr-0" }, [
        _c("label", { attrs: { for: "roles" } }, [
          _vm._v(_vm._s(_vm.$t("roles") + ":"))
        ]),
        _c(
          "select",
          {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.selectedRole,
                expression: "selectedRole"
              }
            ],
            attrs: { name: "roles" },
            on: {
              change: function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selectedRole = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              }
            }
          },
          [
            _c("option", { attrs: { disabled: "", "v-model": null } }, [
              _vm._v(_vm._s(_vm.$t("defaultSelectOption")))
            ]),
            _vm._l(_vm.getAvailableRoles, function(role) {
              return _c(
                "option",
                { key: role.roleId, domProps: { value: role.roleId } },
                [_vm._v(_vm._s(role.name))]
              )
            })
          ],
          2
        )
      ]),
      _c(
        "div",
        { staticClass: "groups-container" },
        [_c("users", { attrs: { collection: _vm.currentCollection } })],
        1
      ),
      _vm.showModal
        ? _c(
            "modal",
            {
              attrs: {
                modalType: _vm.modalType,
                clickOutSideModalHandler: _vm.hideModal,
                showButtons: _vm.showModalButtons
              }
            },
            [_c("add-users", { on: { close: _vm.hideModal } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }