<template>
    <div class="update-element-container column">
        <div class="col-12">
        <div class="row">
            <p>{{this.$t("updateSchoolsText")}}</p>
            <i class="circle close-tag" v-on:click="hideModal"> 
                <div class="cross"></div>
            </i>
        </div>
        </div>

        <div class="update-element-fields column">
            <label for="name" 
                :class="[{visibility: schoolName == '', errorMessage: !isValidSchoolName}]">
                {{`${isValidSchoolName ? $t("addSchoolNameLabelText") 
                                        : !isSchoolNameAvailable ? 
                                                $t("invalidName") 
                                                : $t("mustFillNameField")}`}}</label>
            <input type="text" name="name" 
                    v-on:blur="checkSchoolNameAvailabality" 
                    v-on:focus="focusEventHandler"
                    v-model="schoolName" 
                    :placeholder='$t("addSchoolNameLabelText")'/>
        </div>

        <div class="update-element-buttons">
            <div class="col-12">
                <div class="row">
                    <button @click="cleanFormFields()">
                        <span class='reset-button-text'>
                            {{$t("resetButtonText")}}</span>
                    </button>
                    <button :class="[{'disabled': !validForm}]"
                            @click="updateSchools()">
                        <span class='update-element-submit-button-text'>
                            {{$t("updateButtonText")}}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import AuthenticationService from "@/services/AuthenticationService.js";
import StringUtils from "@/utils/StringUtils.js";

export default{
    name: "UpdateSchools",
    components: {
        StringUtils
    },
    props: {
        school: Object
    },
    data: function(){
        return {
            schoolName: this.school.name,
            isSchoolNameAvailable: true,
            haveNameUpdated: false
        };
    },
    computed: {
        validForm(){
            return  this.isValidSchoolName;
        },
        isValidSchoolName(){
            return this.schoolName.trim() != "" && this.isSchoolNameAvailable;
        },
        currentUser(){
            return AuthenticationService.getProfile();
        }
    },
    methods: {
        updateSchools(){
            if(!this.validForm){
                this.$store.commit('addSnackBarNotification', {
                        message: this.$t("mustFillFields"), 
                        dismissable: false
                    });

                return;
            }

            let _promise = this.haveNameUpdated ? this.$store.state.dealerPromise.promise
                                                    : Promise.resolve();

            _promise.then(() => {
                    this.$store.dispatch("updateSchool", { 
                        ...this.school,
                        name: this.schoolName,
                        districtId: this.currentUser.districtId
                    }).then(() => {
                        this.hideModal();
                        this.$store.commit('addSnackBarNotification', {
                                message: this.$t("successfulUpdateSchoolsMessage"), 
                                dismissable: false
                            });
                    }, error => {
                        this.$store.commit('addSnackBarNotification', {
                                message: this.$t(error), 
                                dismissable: false
                            });
                    });
                }, () => {
                    this.$store.commit('addSnackBarNotification', {
							message: this.$t("invalidName"), 
							dismissable: false
						});
                });
        },
        cleanFormFields(){
            this.schoolName = this.school.name;
            this.haveNameUpdated = false;
        },
        checkSchoolNameAvailabality(){
            if(!this.schoolName)
                return;

            this.$store.dispatch("checkSchoolNameAvailabality", this.schoolName)
                        .then(() => { this.isSchoolNameAvailable = false; })
                        .catch(() => { this.isSchoolNameAvailable = true; });
        },
        focusEventHandler(){
            this.$store.dispatch("setDealerPromise");
            this.isSchoolNameAvailable = true;
            this.haveNameUpdated = true;
        },
        hideModal(){
            this.$emit("close");
        },
        withCapitalLetter(word){
            return StringUtils.withCapitalLetter(word);
        }
    },
    beforeDestroy(){
        this.$store.commit("setDealerPromise", null);
    }
}
</script>
<style lang="less" scoped>
.update-element-container{
    margin: 0px auto;
    width: 329px;
    padding: 20px 0px;

    p{
        margin: 0;

        width: 227px;
        color: rgba(0,0,0,0.77);
        font-family: Montserrat;
        font-size: 24px;
        line-height: 32px;
    }

    .close-tag{
        margin-top: 4px;
        margin-left: 78px;
        background-color: #0A1554;
    }

    .update-element-fields{
        width: 100%;
        margin-top: 30px;
        margin-bottom: 26px;

        label{
            height: 24px;
            color: rgba(0,0,0,0.7);
            font-family: Roboto;
            font-size: 12px;
            line-height: 12px;

            margin-bottom: 6px;

            &.visibility{
                visibility: hidden;
            }
        }

        .errorMessage{
            color: red;
        }

        input{
            color: rgba(0,0,0,0.54);
            font-family: Roboto;
            font-size: 16px;
            line-height: 24px;
            
            width: 100%;
            min-height: 24px;
            max-height: 24px;

            border: none;
            border-bottom: 1px solid #9E9E9E;
            padding-bottom: 3px;

            margin-bottom: 23px;

            &:focus{
                outline: none;
            }

            &:hover{
                cursor: text;
            }
        }
    }

    .update-element-buttons{
        width: 100%;
        margin-bottom: 0;

        button{
            border-radius: 20px;
            text-align: center;

            &:hover{
                cursor: pointer;
            }

            &:focus{
                outline: none;
            }
        }

        button:first-child{
            height: 40px;
            width: 108px;

            box-sizing: border-box;
            border: 2px solid #4356FD;
            background-color: #FFFFFF;

            .reset-button-text{
                height: 16px;
                width: 175px;
                color: #3A4DFD;
                font-family: Montserrat;
                font-size: 13.96px;
                font-weight: 500;
                letter-spacing: 1.25px;
                line-height: 16px;
                text-align: center;
            }
        }

        button:nth-child(2){
            height: 42px;
            width: 199px;

            margin-left: 22px;
            box-sizing: border-box;

            background-color: #0A1554;
            border: none;

            .update-element-submit-button-text{
                height: 16px;
                width: 64px;
                color: white;
                font-family: Montserrat;
                font-size: 13.96px;
                font-weight: 500;
                letter-spacing: 1.25px;
                line-height: 16px;
                text-align: center;
            }
        }

        button.disabled{
            background-color: rgba(158, 158, 158, 0.801);
        }
    }
}
</style>
