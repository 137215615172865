<template>
    <div class="management-dashboard-section-container">
        <div class="button-container">
            <button class="btn round primary" v-on:click="showAddDistrictsModal()">
                <span>{{$t("addDistrictsText").toUpperCase()}}</span></button>
        </div>
        <div class="section-header">
            <div class="col-12">
                <div class="row">
                    <label for="states">
                        {{`${withCapitalLetter($t("states"))}:`}}</label>
                    <select name="states" v-model="selectedState"
                                        v-on:change="changeEventHandler">
                        <option disabled v-bind:v-model="null">
                            {{$t("defaultSelectOption")}}</option>
                        <option v-for="state in states"
                                :key="state.id"
                                :value="state.id">{{state.name}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="section-body">
            <table>
                <thead>
                    <tr>
                        <th>{{withCapitalLetter($t("name"))}}</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(district, index) in partialCollection" :key="index">
                        <td>{{district.name}}</td>
                        <td>
                            <img :src="getEditIcon" 
                                v-on:click="showUpdateDistrictModal(district)" />
                            <img :src="getDeleteIcon" 
                                v-on:click="showDeleteConfirmation(district)" />
                        </td>
                    </tr>
                    <tr v-if="partialCollection.length === 0">
                        <td class="no-content" colspan="2">{{$t("noContent")}}</td>
                    </tr>
                </tbody>
            </table>
            
            <paginator  :collection="collection" 
                        v-on:onPartialCollectionUpdate="eventHandler"/>
        </div>
        
        <modal v-if="showModal"
            :modalType="modalType"
            :clickOutSideModalHandler="hideModal"
            :showButtons="showModalButtons">
            <component :is="currentModal"
                        :district="selectedDistrict"
                        v-on:close="hideModal"/></modal>

        <modal v-if="showDeleteModal"
            :click-handler="deleteDistrict"
            :clickOutSideModalHandler="hideModal"
            v-on:close="hideModal">
            <div class="removeQuestion">  
                <span class="removeQuestion">
                    {{`${$t("wantToDeleteDistrictQuestion")}: ${selectedDistrict.name}?`}}</span>
            </div>
        </modal>
    </div>
</template>

<script>

import AddDistricts from "@/components/BackOffice/ManagementDashboard/Districts/AddDistricts.vue";
import UpdateDistricts from "@/components/BackOffice/ManagementDashboard/Districts/UpdateDistricts.vue";
import Modal from "@/common/Modal.vue";
import Paginator from "@/common/Paginator.vue";
import AuthenticationService from "@/services/AuthenticationService.js";
import StringUtils from "@/utils/StringUtils.js";

export default {
    name: "Districts",
    components: {
        "add-districts": AddDistricts,
        "update-districts": UpdateDistricts,
        Modal,
        Paginator,
        StringUtils
    },
    data: function(){
        return {
            currentModal: "",
            showModal: false,
            showDeleteModal: false,
            showModalButtons: false,
            modalType: "vocabularyModal",
            selectedDistrict: null,
            partialCollection: []
        }
    },
    computed: {
        currentCollection(){
            return this.$store.state.usersCollection[this.selectedRole] || { users: [] };
        },
        currentUser(){
            return AuthenticationService.getProfile();
        },
        getDeleteIcon(){
            return require("@/assets/icons/ic_delete.svg");
        },
        getEditIcon(){
            return require("@/assets/icons/ic_edit.svg");
        },
        states(){
            return this.$store.state.managementObj.states;
        },
        collection(){
            return this.$store.state.managementObj.districts;
        },
        selectedState: {
            get(){
                return this.$store.state.managementObj.currentState;
            },
            set(value){
                this.$store.commit("setCurrentState", value);
            }
        }
    },
    methods: {
        hideModal(){
            this.showModal = false;
            this.showDeleteModal = false;
        },
        showAddDistrictsModal(){
            this.currentModal = "add-districts";
            this.showModal = true;
        },
        showUpdateDistrictModal(district){
            this.currentModal = "update-districts";
            this.selectedDistrict = { ...district, stateId: this.selectedState };
            this.showModal = true;
        },
        showDeleteConfirmation(district){
            this.selectedDistrict = district;
            this.showDeleteModal = true;
        },
        deleteDistrict(){
            this.showDeleteModal = false;
            this.$store.dispatch("deleteDistrict", this.selectedDistrict)
                    .then(() => {
                        this.$store.commit('addSnackBarNotification', {
                            message: this.$t("successfulDistrictDeletingMessage"), 
                            dismissable: false
                        });

                        this.selectedDistrict = null;
                    }, error => {
                        this.$store.commit('addSnackBarNotification', {
                                message: this.$t(error), 
                                dismissable: false
                            });
                    });
        },
        eventHandler(event){
            this.partialCollection = event;
        },
        changeEventHandler(event){
            this.$store.dispatch("getDistrictsByState", event.target.value)
                .then(null, error => {
                    this.$store.commit('addSnackBarNotification', {
                        message: this.$t(error), 
                        dismissable: true
                    });
                });
        },
        withCapitalLetter(word){
            return StringUtils.withCapitalLetter(word);
        }
    },
    created: function(){
        this.$store.dispatch("getStates")
            .then(states => {
                this.selectedState = states[0].id;

                this.$store.dispatch("getDistrictsByState", this.selectedState)
                    .then(null, error => {
                            this.$store.commit('addSnackBarNotification', {
                                message: this.$t(error), 
                                dismissable: true
                        });
                    });
            }, error => {
                this.$store.commit('addSnackBarNotification', {
                    message: this.$t(error), 
                    dismissable: true
                });
            });

        this.$store.commit("setManagementDashboardNavBarPath", {
            parent: this.$t("management"),
            child: this.$t("districts")
        });
    },
    beforeDestroy(){
        this.$store.commit("setCurrentState", null);
    }
}

</script>

<style lang="less" scoped>

.management-dashboard-section-container{
    width: 968px;
    margin: 10px auto;

    .button-container{
        width: 100%;
        margin-bottom: 5px;

        button{
            height: 40px;
            width: 168px;
            margin-left: 798px;
        }
    }

    .section-header{
        height: 56px;
        color: white;
        background-color: #0A1554;
        line-height: 56px;

        label{
            margin-left: auto;
            margin-right: 10px;
        }

        select{
            min-height: 24px;
            max-height: 24px;
            width: 150px;
            margin-right: 10px;
            margin-top: 16px;

            option{
                line-height: 16px;
                font-size: 16px;
            }
        }
    }

    .section-body{
        max-width: 968px;
        height: calc(100vh - 225px);
        overflow-y: auto;

        &::-webkit-scrollbar{
            display: none;
        }

        table{
            width: 100%;
            border-collapse: collapse;

            tr{
                height: 56px;
            }

            thead{
                th{
                    text-align: left;
                    width: 90%;

                    :last-child{
                        width: 10%;
                    }
                }
            }

            tbody{
                border-top: 1px solid grey;
                border-bottom: 1px solid grey;

                img{
                    margin-left: 20px;

                    &:hover{
                        cursor: pointer;
                    }
                }

                .no-content{
                    text-align: center;
                }
            }
        }

        .removeQuestion{
            padding: 20px 0px;
        }

        & /deep/ .paginator-container{
            margin-top: 5px;
        }
    }
}

</style>

