var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "addUsers-container column" }, [
    _c("div", { staticClass: "header row ml-0 mr-0" }, [
      _c("span", [_vm._v(_vm._s(_vm.$t("addUsersHeaderText")))]),
      _c("span", { staticClass: "close-icon", on: { click: _vm.hideModal } }, [
        _vm._v("×")
      ])
    ]),
    _c(
      "form",
      {
        attrs: { method: "post", autocomplete: "off" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.addUsers($event)
          }
        }
      },
      [
        _c(
          "div",
          { staticClass: "addUsers-credentials column" },
          [
            _c(
              "label",
              {
                class: [
                  {
                    visibility: _vm.firstName == "",
                    errorMessage: !_vm.isValidFirstName
                  }
                ],
                attrs: { for: "firstName" }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      "" +
                        (_vm.isValidFirstName
                          ? _vm.$t("addUsersFirstNameLabelText")
                          : _vm.$t("mustFillNameField"))
                    )
                )
              ]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.firstName,
                  expression: "firstName"
                }
              ],
              attrs: {
                type: "text",
                name: "firstName",
                placeholder: _vm.$t("addUsersFirstNameLabelText")
              },
              domProps: { value: _vm.firstName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.firstName = $event.target.value
                }
              }
            }),
            _c(
              "label",
              {
                class: [{ visibility: _vm.middleName == "" }],
                attrs: { for: "middleName" }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("addUsersMiddleNameLabelText"))
                )
              ]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.middleName,
                  expression: "middleName"
                }
              ],
              attrs: {
                type: "text",
                name: "middleName",
                placeholder: _vm.$t("addUsersMiddleNameLabelText")
              },
              domProps: { value: _vm.middleName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.middleName = $event.target.value
                }
              }
            }),
            _c(
              "label",
              {
                class: [
                  {
                    visibility: _vm.lastName == "",
                    errorMessage: !_vm.isValidLastName
                  }
                ],
                attrs: { for: "lastName" }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      "" +
                        (_vm.isValidLastName
                          ? _vm.$t("addUsersLastNameLabelText")
                          : _vm.$t("mustFillNameField"))
                    )
                )
              ]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.lastName,
                  expression: "lastName"
                }
              ],
              attrs: {
                type: "text",
                name: "lastName",
                placeholder: _vm.$t("addUsersLastNameLabelText")
              },
              domProps: { value: _vm.lastName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.lastName = $event.target.value
                }
              }
            }),
            _c(
              "label",
              {
                class: [{ visibility: _vm.secondLastName == "" }],
                attrs: { for: "secondLastName" }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.$t("addUsersSecondLastNameLabelText"))
                )
              ]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.secondLastName,
                  expression: "secondLastName"
                }
              ],
              attrs: {
                type: "text",
                name: "secondLastName",
                placeholder: _vm.$t("addUsersSecondLastNameLabelText")
              },
              domProps: { value: _vm.secondLastName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.secondLastName = $event.target.value
                }
              }
            }),
            _c(
              "label",
              {
                class: [
                  {
                    visibility: _vm.emailAddress == "",
                    errorMessage: !_vm.isValidEmailAddress
                  }
                ],
                attrs: { for: "emailaddress" }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      "" +
                        (_vm.isValidEmailAddress
                          ? _vm.$t("addUsersEmailAddressLabelText")
                          : _vm.$t("invalidEmailAddress"))
                    )
                )
              ]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.emailAddress,
                  expression: "emailAddress"
                }
              ],
              attrs: {
                type: "text",
                name: "emailaddress",
                placeholder: _vm.$t("addUsersEmailAddressLabelText")
              },
              domProps: { value: _vm.emailAddress },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.emailAddress = $event.target.value
                }
              }
            }),
            _c(
              "label",
              {
                class: [
                  {
                    visibility: _vm.username == "",
                    errorMessage: !_vm.isValidUsername
                  }
                ],
                attrs: { for: "username" }
              },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      "" +
                        (_vm.isValidUsername
                          ? _vm.$t("username")
                          : _vm.isUserNameAvailable
                            ? _vm.$t("invalidUsername")
                            : _vm.$t("usernameInUse"))
                    )
                )
              ]
            ),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.username,
                  expression: "username"
                }
              ],
              attrs: {
                type: "text",
                name: "username",
                placeholder: _vm.$t("username")
              },
              domProps: { value: _vm.username },
              on: {
                blur: _vm.checkUsernameAvailabality,
                focus: _vm.focusEventHandler,
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.username = $event.target.value
                }
              }
            }),
            _c("label", { class: [{ errorMessage: !_vm.isValidBirthDate }] }, [
              _vm._v(
                "\n            " +
                  _vm._s(
                    "" +
                      (_vm.isValidBirthDate
                        ? _vm.$t("birthDate")
                        : _vm.$t("invalidBirthDate"))
                  )
              )
            ]),
            _c("datepicker", {
              attrs: {
                language: _vm.datePickerLanguage,
                format: _vm.birthDateFormat
              },
              model: {
                value: _vm.birthDate,
                callback: function($$v) {
                  _vm.birthDate = $$v
                },
                expression: "birthDate"
              }
            }),
            _c("label", { attrs: { for: "userRole" } }, [
              _vm._v(_vm._s(_vm.$t("addUsersRoleLabelText")))
            ]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedRole,
                    expression: "selectedRole"
                  }
                ],
                attrs: { name: "userRole" },
                on: {
                  change: [
                    function($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function(o) {
                          return o.selected
                        })
                        .map(function(o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedRole = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    _vm.selectOnChangeEvent
                  ]
                }
              },
              [
                _c("option", { attrs: { disabled: "", value: "-1" } }, [
                  _vm._v(_vm._s(_vm.$t("defaultSelectOption")))
                ]),
                _vm._l(_vm.toAddAllowedRoles, function(role, index) {
                  return _c(
                    "option",
                    { key: index, domProps: { value: role.roleId } },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(role.name) +
                          "\n            "
                      )
                    ]
                  )
                })
              ],
              2
            ),
            _vm.showDistrictField
              ? [
                  _c("label", { attrs: { for: "district" } }, [
                    _vm._v(_vm._s(_vm.$t("addUsersDistrictLabelText")))
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedDistrict,
                          expression: "selectedDistrict"
                        }
                      ],
                      attrs: { name: "district" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedDistrict = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { disabled: "", value: "-1" } }, [
                        _vm._v(_vm._s(_vm.$t("defaultSelectOption")))
                      ]),
                      _vm._l(_vm.toAddAllowedDistrict, function(
                        district,
                        index
                      ) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: district.id } },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(district.name) +
                                "\n                "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ]
              : _vm._e(),
            _vm.showCampusField
              ? [
                  _c("label", { attrs: { for: "campus" } }, [
                    _vm._v(_vm._s(_vm.$t("addUsersCampusLabelText")))
                  ]),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedCampus,
                          expression: "selectedCampus"
                        }
                      ],
                      attrs: { name: "campus" },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedCampus = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { disabled: "", value: "-1" } }, [
                        _vm._v(_vm._s(_vm.$t("defaultSelectOption")))
                      ]),
                      _vm._l(_vm.toAddAllowedCampus, function(campus, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: campus.id } },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(campus.name) +
                                "\n                "
                            )
                          ]
                        )
                      })
                    ],
                    2
                  )
                ]
              : _vm._e()
          ],
          2
        ),
        _c("div", { staticClass: "buttons row" }, [
          _c(
            "button",
            {
              staticClass: "btn secondary",
              on: { click: _vm.cleanFormFields }
            },
            [_vm._v(_vm._s(_vm.$t("cleanButtonText")))]
          ),
          _c("input", {
            staticClass: "btn primary",
            class: [{ disabled: !_vm.validForm }],
            attrs: { type: "submit" },
            domProps: { value: _vm.$t("addUsersButtonText") }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }